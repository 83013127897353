<template>
  <section id="WhoWeAreMobile">
    <SideBarNavMobile subsectionTitle="Who We Are" />
    <div class="whoweare-cnt top">
      <div class="whoweare-section-cnt">
        <div class="whoweare-title-cnt">
          <div class="whoweare-title">Sales</div>
          <div class="line-divider"></div>
        </div>
        <div class="whoweare-desc">
          Meet your first-round draft picks. The Sales team will play an
          essential role in tailoring Allegiant Stadium’s event spaces to your
          specific needs.
        </div>
        <div class="rep-box" v-for="rep in sales" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
          />
          <div class="rep-name">{{ rep.name }}</div>
          <div class="rep-title">{{ rep.title }}</div>
          <div class="rep-desc">{{ rep.description }}</div>
        </div>
      </div>

      <div class="whoweare-section-cnt">
        <div class="whoweare-title-cnt">
          <div class="whoweare-title">Event Management</div>
          <div class="line-divider"></div>
        </div>
        <div class="whoweare-desc">
          Meet your playmakers. The Event Management Team will work with you on
          every detail to produce a comprehensive event plan that is executed
          flawlessly.
        </div>
        <div class="rep-box" v-for="rep in events" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
          />
          <div class="rep-name">{{ rep.name }}</div>
          <div class="rep-title">{{ rep.title }}</div>
          <div class="rep-desc">{{ rep.description }}</div>
        </div>
      </div>

      <div class="whoweare-section-cnt">
        <div class="whoweare-title-cnt">
          <div class="whoweare-title">Silver &amp; Black Hospitality</div>
          <div class="line-divider"></div>
        </div>
        <div class="whoweare-desc">
          Meet your starting line-up. Silver &amp; Black Hospitality will work
          with you to assemble a world-class, delicious menu to bring your
          culinary vision to life.
        </div>
        <div class="rep-box" v-for="rep in silver" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
          />
          <div class="rep-name">{{ rep.name }}</div>
          <div class="rep-title" v-html="rep.title" />
          <div class="rep-desc">{{ rep.description }}</div>
        </div>
      </div>
    </div>
    <div class="whoweare-cnt bottom">
      <div class="whoweare-section-cnt">
        <div class="whoweare-title-cnt">
          <div class="whoweare-title">Game Plan</div>
          <div class="line-divider"></div>
        </div>
        <div class="gameplan-box">
          <div class="gameplan-num">1</div>
          <div class="gameplan-title">STARTING FIELD POSITION!</div>
          <div class="gameplan-desc">
            Kickoff your event experience by planning and contracting your event
            with the Sales Team
          </div>
        </div>
        <div class="gameplan-box">
          <div class="gameplan-num">2</div>
          <div class="gameplan-title">TO THE 40!</div>
          <div class="gameplan-desc">
            Move the ball down the field with the Catering Team that will build
            out your menu
          </div>
        </div>
        <div class="gameplan-box">
          <div class="gameplan-num">3</div>
          <div class="gameplan-title">MIDFIELD!</div>
          <div class="gameplan-desc">
            Huddle up with the Events Team to ensure the game plan is built for
            success
          </div>
        </div>
        <div class="gameplan-box">
          <div class="gameplan-num">4</div>
          <div class="gameplan-title">REDZONE!</div>
          <div class="gameplan-desc">
            Get into the Redzone and prepare to score by locking in all event
            details prior to the event
          </div>
        </div>
        <div class="gameplan-box">
          <div class="gameplan-num">5</div>
          <div class="gameplan-title">TOUCHDOWN!</div>
          <div class="gameplan-desc">
            Score big with an event at Allegiant Stadium
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBarNavMobile from "@/components/Nav/SideBarNavMobile";

export default {
  name: "WhoWeAreMobile",
  components: {
    SideBarNavMobile,
  },
  data() {
    return {
      sales: [
        {
          image: "rep-samantha.png",
          name: "Samantha Johnson",
          title: "Senior Director of Sales and Marketing",
          description:
            "Samantha (Sam) combines her passion for event planning with her expertise in stadium operations to ensure each event is successful at Allegiant Stadium.",
        },
        {
          image: "rep-ashley.png",
          name: "Ashley Hinsen",
          title: "Senior Director of Sales",
          description:
            "Ashley curates out of the box experiences by channeling her event design background to guide each client into an unforgettable experience at Allegiant Stadium.",
        },
        {
          image: "rep-carissa.png",
          name: "Carissa Callaway",
          title: "Sales Manager",
          description:
            "Carissa enjoys creating one-of-a-kind experiences for her customers by channeling her experiences in executing high end events and building strong partnerships with her clients at Allegiant Stadium.",
        },
        {
          image: "rep-anja.png",
          name: "Anja Marcusiu",
          title: "Sales Manager",
          description:
            "Anja specializes in delivering world-class events by turning client’s visions into realities for an extraordinary experience.",
        },
      ],
      events: [
        {
          image: "rep-nicole.png",
          name: "Nicole Francis",
          title: "Senior Director of Private Events",
          description:
            "Nicole has an unparalleled knack for curating seamless and unforgettable experiences and has orchestrated a myriad of successful gatherings that blend elegance, innovation, and meticulous attention to detail at Allegiant Stadium.",
        },
        {
          image: "rep-jessica.png",
          name: "Jessica LaRoussa",
          title: "Corporate Event Manager",
          description:
            "Jessica is passionate about understanding the specific needs of each client, ensuring that their unique event goals are met with exceptional professionalism and expertise at Allegiant Stadium.",
        },
        {
          image: "rep-natalie.png",
          name: "Natalie Revesz",
          title: "Corporate Event Manager",
          description:
            "Natalie applies her sports, entertainment and events background to collaborate with clients and curate memorable experiences at Allegiant Stadium.",
        },
        {
          image: "rep-allie.png",
          name: "Allie Laskaris",
          title: "Assistant Manager of Corporate Events",
          description:
            "Allie utilizes her creative problem-solving skills and detailed project management experience to enhance each client’s experience.",
        },
        {
          image: "rep-brittney.png",
          name: "Brittney Yoshida",
          title: "Assistant Manager of Corporate Events",
          description:
            "Brittney combines her expertise in planning a variety of events and her client relation skills to proactively guide clients through their Allegiant Stadium event experience.",
        },
      ],
      silver: [
        {
          image: "rep-angelina.png",
          name: "Angelina Martinez",
          title: "National Sales Director, Levy Restaurants",
          description:
            "Angelina utilizes her diverse experience in the Las Vegas events industry to create world class menus and deliver best in class customer service.",
        },
        {
          image: "rep-tara.png",
          name: "Tara Sasser",
          title:
            "Area Sales Director, Silver &amp; Black Hospitality, Levy Restaurants",
          description:
            "Tara specializes in customer satisfaction, utilizing her eleven years of experience in sports and entertainment venues to offer a one-of-a kind event planning experience.",
        },
        {
          image: "rep-jenna.png",
          name: "Jenna Charles",
          title: "Catering Sales Manager, Silver &amp; Black Hospitality",
          description:
            "Jenna-Kaya brings excellent customer service to go above and beyond to create connections with efficiency and focus in building future growth.",
        },
        {
          image: "rep-manny.png",
          name: "Manny Gerardo",
          title: "Director of Banquets, Silver &amp; Black Hospitality",
          description:
            "Manny’s passion for hospitality along with his diverse background of 23 years in the food and beverage allows him to exceed every guest’s expectation and make your event the most memorable experience you ever had at a sporting venue.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#WhoWeAreMobile {
  .whoweare-cnt {
    padding: 0 20px;
    &.top {
      padding-top: 170px;
      background: url("~@/assets/images/MobileBG/Who-We-Are-Section-1-BG.jpg")
        fixed no-repeat center center;
      background-size: cover;
    }
    &.bottom {
      padding-top: 100px;
      background: url("~@/assets/images/MobileBG/Who-We-Are-Section-2-BG.jpg")
        fixed no-repeat center center;
      background-size: cover;
    }
    .whoweare-section-cnt {
      padding-bottom: 100px;
      .whoweare-title-cnt {
        display: inline-flex;
        flex-wrap: wrap;
        align-self: flex-start;
        margin-bottom: 25px;
        .whoweare-title {
          width: 100%;
          margin-bottom: 25px;
          font-size: 35px;
          font-family: "ArtegraSans-Bold";
          line-height: 30px;
          letter-spacing: 2.19px;
          text-transform: uppercase;
          color: #ffffff;
        }
        .line-divider {
          position: relative;
          width: 100%;
          height: 2px;
          background: #ffffff;
          box-shadow: 0 0 9px 2px #ffffff;
        }
      }
      .whoweare-desc {
        font-size: 16px;
        font-family: "ArtegraSans-Regular";
        line-height: 23px;
        letter-spacing: 1.07px;
        color: #ffffff;
        margin-bottom: 40px;
      }
      .rep-box {
        width: 80%;
        margin: auto auto 50px auto;
        text-align: center;
        @media (max-width: $sm) {
          width: 100%;
        }
        img {
          width: 50%;
          margin: auto;
          @media (max-width: $sm) {
            width: 60%;
          }
        }
        .rep-name {
          display: block;
          width: 100%;
          font-size: 16px;
          font-family: "ArtegraSans-Bold";
          letter-spacing: 1px;
          line-height: 30px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }
        .rep-title {
          display: block;
          width: 100%;
          margin-top: -10px;
          font-size: 14px;
          font-family: "ArtegraSans-Bold";
          line-height: 30px;
          letter-spacing: 0.88px;
          text-align: center;
          text-transform: uppercase;
          color: #989898;
        }
        .rep-desc {
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 15px;
          font-family: "ArtegraSans-Regular";
          line-height: 25px;
          letter-spacing: 1.19px;
          text-align: center;
          color: #ffffff;
        }
      }
      .gameplan-box {
        margin: 0 0 25px 0;
        .gameplan-num {
          width: 26px;
          height: 26px;
          background-color: #ffffff;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          color: #000000;
          box-shadow: 0 0 10px rgba(255, 255, 255, 1);
          margin: 10px 0 15px 0;
        }
        .gameplan-title {
          font-size: 16px;
          font-family: "ArtegraSans-Bold";
          line-height: 21px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #ffffff;
        }
        .gameplan-desc {
          font-size: 16px;
          font-family: "ArtegraSans-Regular";
          line-height: 21px;
          letter-spacing: 1px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
